import JsPDF from 'jspdf'
import html2Canvas from 'html2canvas'
import { Loading } from 'element-ui' //此处根据你实际使用的框架的loading进行引入
let loading
export function getPdf(id, pdfTitle, width, height, type) {
  let isSingle = type === 'single'
  let isA4 = type === 'a4'
  loading = Loading.service({
    lock: true,
    text: '正在下载pdf',
    customClass: 'pdfLoading'
  })
  html2Canvas(document.querySelector(`#${id}`), {
    allowTaint: true,
    useCORS: true,
    scale: 3
  }).then(function (canvas) {
    loading.close()
    let context = canvas.getContext('2d') // 关闭抗锯齿
    context.mozImageSmoothingEnabled = false
    context.webkitImageSmoothingEnabled = false
    context.msImageSmoothingEnabled = false
    context.imageSmoothingEnabled = false
    if (isSingle) {
      var pageData = canvas.toDataURL('image/jpeg', 1.0)
      var img = new Image()
      img.src = pageData
      img.onload = function () {
        // 获取dom高度、宽度
        img.width = img.width / 2
        img.height = img.height / 2
        img.style.transform = 'scale(0.5)'
        var pdf = ''
        if (width > height) {
          // 此可以根据打印的大小进行自动调节
          pdf = new JsPDF('l', 'mm', [width * 0.505, height * 0.505])
        } else {
          pdf = new JsPDF('p', 'mm', [width * 0.505, height * 0.505])
        }
        pdf.addImage(pageData, 'jpeg', 0, 0, width * 0.505, height * 0.505)
        pdf.save(pdfTitle + '.pdf')
      }
    } else if (isA4) {
      // a4纸的尺寸[592.28,841.89]
      const A4_WIDTH = 592.28
      const A4_HEIGHT = 841.89
      let contentWidth = canvas.width
      let contentHeight = canvas.height
      let pageData = canvas.toDataURL('image/jpeg', 0.6)
      // 一页pdf html页面生成的canvas高度;
      let pageHeight = (contentWidth / A4_WIDTH) * A4_HEIGHT
      // 未生成pdf的html页面高度
      let leftHeight = contentHeight
      // 页面偏移
      let position = 0
      // a4纸的尺寸[592.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      let imgWidth = A4_WIDTH
      let imgHeight = (A4_WIDTH / contentWidth) * contentHeight

      // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
      let pdf = new JsPDF('', 'pt', 'a4')
      // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度
      // 当内容未超过pdf一页显示的范围，无需分页
      if (leftHeight < pageHeight) {
        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
      } else {
        while (leftHeight > 0) {
          pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
          leftHeight = leftHeight - pageHeight
          position = position - A4_HEIGHT
          // 避免添加空白页
          if (leftHeight > 0) {
            pdf.addPage()
          }
        }
      }
      pdf.save(pdfTitle + '.pdf')
    } else {
      // 宽高[900,560.25]
      // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75
      let pdfWidth = width * 0.75
      let pdfHeight = (height + 0.3) * 0.75
      let contentWidth = canvas.width
      let contentHeight = canvas.height
      let pageHeight = (contentWidth / pdfWidth) * pdfHeight
      let leftHeight = contentHeight
      let position = 0
      let imgWidth = pdfWidth
      let imgHeight = (pdfWidth / contentWidth) * contentHeight
      let pageData = canvas.toDataURL('image/jpeg', 1.0)
      let PDF = new JsPDF('l', 'pt', [pdfWidth, pdfHeight])
      if (leftHeight < pageHeight) {
        PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
      } else {
        while (leftHeight > 0) {
          PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
          leftHeight -= pageHeight
          position -= pdfHeight
          if (leftHeight > 0) {
            PDF.addPage()
          }
        }
      }
      PDF.save(pdfTitle + '.pdf')
    }
  })
}
